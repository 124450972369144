import React, {useEffect, useState} from 'react';
import path from 'path';
import {parseNumber} from '@helpers/common/number.mjs';
import {useDatabaseCartProviderV3} from '@components/cart/CartProvider.jsx';
import {HStack, NextLink, Spinner} from '@components/widgets/Common';
import {useWorking} from '@components/widgets/UseWorking';

import {isofetch} from '@helpers/common/fetch.mjs';

export function ListSuggestions({vsItem, suggestions}) {
  let suggestionsUnfiltered = suggestions?.flatMap((category) => category?.products);
  suggestionsUnfiltered = suggestionsUnfiltered?.filter((x) => x?.slug) ?? [];
  console.log('suggestionsUnfiltered', suggestionsUnfiltered);

  const {isWorking, wrapWorking} = useWorking();
  let [products, setProducts] = useState([]);

  const loadProductDetails = wrapWorking(async () => {
    let responses = await Promise.all(
      suggestionsUnfiltered?.map(async (x) => {
        if (!x?.properSkuList || !x?.properSkuList?.length) {
          return null;
        }
        let sku = x?.properSkuList?.[0];
        const data = await isofetch.get('/api/product/' + encodeURIComponent(sku));
        return data;
      })
    );

    responses = responses?.filter(Boolean);
    responses = responses?.map((x) => {
      return {
        ...x,
        vsItemSize: parseNumber(vsItem?.variant?.props?.['Subwoofer Size']),
        skuSize: parseNumber(x.props?.Size)
      };
    });

    // console.table(responses, ['sku', 'vsItemSize', 'skuSize'])
    responses = responses?.filter((x) => x?.vsItemSize && x?.vsItemSize === x?.skuSize);
    setProducts(responses);
  });

  useEffect(() => {
    if (!isWorking) {
      loadProductDetails()?.then();
    }
  }, [suggestions, isWorking, loadProductDetails]);

  if (isWorking && !products?.length) {
    return (
      <div className="py-3 px-5">
        <Spinner size={16} />
      </div>
    );
  }

  if (!products?.length) {
    return null;
  }

  return (
    <div>
      <p>Instead you can add these products:</p>
      <ul className="list-disc pl-4 py-3">
        {products?.map((prod, index) => (
          <SuggestedItem key={index} prod={prod} />
        ))}
      </ul>
    </div>
  );
}

export function SuggestedItem({prod}) {
  const {addToCart} = useDatabaseCartProviderV3();
  const addSkuToCart = async () => {
    await addToCart({
      sku: prod.sku,
      quantity: 1,
      price: prod.price,
      price_map: prod.price_map,
      price_msrp: prod.price_msrp,
      path: prod.slug,
      variant: prod
    });
  };

  let href = prod?.slug?.startsWith('/products') ? prod?.slug : path.join('/products', prod?.slug);
  return (
    <li className="mb-1">
      ✔{' '}
      <NextLink href={href} className="hover:underline">
        {prod?.sku}
      </NextLink>{' '}
      <SpinnerButton
        onClick={addSkuToCart}
        size={12}
        className="border py-0 px-2 rounded border-lime-500"
        style={{backgroundColor: '#00ca00'}}>
        Add
      </SpinnerButton>
    </li>
  );
}

function SpinnerButton({onClick, className, children, style, size}) {
  const {isWorking, wrapWorking} = useWorking();

  const wrappedOnClick = wrapWorking(onClick);

  return (
    <button className={className} onClick={wrappedOnClick} style={style}>
      <HStack gap={3}>
        {isWorking && <Spinner size={size} />}
        {children}
      </HStack>
    </button>
  );
}
